import React, { useEffect } from "react"

const Modal = props => {
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "unset"
    }
  })

  return <>{props.children}</>
}

export default Modal
